@font-face {
  font-family: 'Gotham Rounded';
  src: url('./assets/fonts/GothamRounded-Book.eot');
  src: url('./assets/fonts/GothamRounded-Book.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/GothamRounded-Book.woff2') format('woff2'), url('./assets/fonts/GothamRounded-Book.woff') format('woff'), url('./assets/fonts/GothamRounded-Book.ttf') format('truetype'), url('./assets/fonts/GothamRounded-Book.svg#GothamRounded-Book') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Rounded';
  src: url('./assets/fonts/GothamRounded-Light.eot');
  src: url('./assets/fonts/GothamRounded-Light.eot?#iefix') format('embedded-opentype'), url('assets/fonts/GothamRounded-Light.woff2') format('woff2'), url('./assets/fonts/GothamRounded-Light.woff') format('woff'), url('./assets/fonts/GothamRounded-Light.ttf') format('truetype'), url('./assets/fonts/GothamRounded-Light.svg#GothamRounded-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Rounded';
  src: url('./assets/fonts/GothamRounded-Medium.eot');
  src: url('./assets/fonts/GothamRounded-Medium.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/GothamRounded-Medium.woff2') format('woff2'), url('./assets/fonts/GothamRounded-Medium.woff') format('woff'), url('./assets/fonts/GothamRounded-Medium.ttf') format('truetype'), url('./assets/fonts/GothamRounded-Medium.svg#GothamRounded-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Rounded';
  src: url('./assets/fonts/GothamRounded-Bold.eot');
  src: url('./assets/fonts/GothamRounded-Bold.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/GothamRounded-Bold.woff2') format('woff2'), url('./assets/fonts/GothamRounded-Bold.woff') format('woff'), url('./assets/fonts/GothamRounded-Bold.ttf') format('truetype'), url('./assets/fonts/GothamRounded-Bold.svg#GothamRounded-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

:root {
  --bg-theme: #509ee3;
  --bs-white: #ffffff;
  --bs-black: #000000;
  --bg-theme-dark: #509ee3;
  --bg-theme-light: #509ee3;
  --bg-theme5171: #3e5171;
  --bg-themef8f4: #eef8f4;
  --bg-theme-light-100: #f2f9ff;
  --bg-theme-light-200: #c3d4e2;
  --bg-theme-light-300: #f1f9ff;
  --bg-green-light-200: #bfb;
  --bg-green-light-300: #4c4;
  --bg-green-light-400: #509ee314;
  --bg-red-light-100: #d34c5c;
  --hover: #238696;
  --gray0b0: #afb0b0;
  --colordc4: #16cdc4;
  --bg-grayf2: #f2f2f2;
  --bg-grayeb: #ebebeb;
  --bg-grayd2: #d2d2d2;
  --bg-grayda: #ced4da;
  --bg-gray37: #00263f;
  --bg-gray6b: #919191;
  --bg-graycc: #cccccc;
  --bg-graydd: #dddddd;
  --bg-graybb: #bbbbbb;
  --bg-grayee: #eeeeee;
  --bg-gray11: #111111;
  --bg-gray88: #888888;
  --bg-grayce: #cacece;
  --bg-grayc9: #c7cac9;
  --bg-graye0: #f2dfe0;
  --bg-gray88: #bb8988;
  --bg-graybb: #bbffbb;
  --bg-gray44: #44cc44;
  --bg-graye6: #dee2e6;
  --bg-yellow: #ffff00;
  --border-fd: #dfdfdf;
  --darkgreen: #006400;
  --color-696: #238696;
  --color-666: #ffd966;
  --color-c6c: #6c6c6c;
  --color-b7b: #7b7b7b;
  --color-3ff: #3333ff;
  --bg-colorc0b3: #a1c0b3;
  --bg-color91c7: #7291C7;
  --bg-white-200: #f5f5f5;
  --border-100: #d7d9d8;
  --roboto: Roboto;
  --gotham: gotham rounded;
  /* --gotham: Gotham Rounded; */
  --bold: 700;
}

@media print {
  body {
    visibility: hidden;
  }

  #section-to-print,
  #section-to-print * {
    visibility: visible;
  }

  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  #section-to-print {
    display: block !important;
  }
}

body {
  background: var(--bs-white);
  font-family: var(--gotham) !important;
  letter-spacing: 0;
}

.App {
  margin-top: 0px;
  background: var(--bg-grayf2);
}

.App > div {
  height: 100%;
}

#container {
  border-radius: 40px 40px 0px 0px;
  margin-left: 200px;
  margin-top: 71px;
  padding: 40px 50px 65px 40px;
  background-color: var(--bs-white);
  height: 100%;
}

#container.bg-white {
  height: 100vh !important;
}

.themeColor {
  color: var(--bg-theme) !important;
}

.request_title {
  font-family: var(--gotham);
  padding-top: 0 !important;
  font-size: 19px !important;
  line-height: 36px;
  font-weight: 500 !important;
  color: var(--bg-gray37);
  margin-top: 0;
}

.site-background {
  background: var(--bg-theme);
}

.body-container {
  border: 1px solid var(--bs-white);
  border-radius: 40px 40px 0px 0px;
  margin-left: 235px;
  margin-top: 0px;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important
}

/* .themeBG {
  background: var(--bg-green-light-400) !important;
  color: var(--bs-white) !important;
  font-size: 12px !important;
  letter-spacing: 0;
} */

.themeBG {
  background: var(--bg-green-light-400) !important;
  color: var(--bs-white) !important;
  font-size: 12px !important;
  letter-spacing: 0;
}

.efunder-btn {
  background-color: var(--bg-theme);
  color: var(--bs-white) !important;
  text-align: center;
  display: inline-block;
  padding: 0 15px;
  height: 38px;
  line-height: 38px;
  border: none;
  text-transform: uppercase;
  border-radius: 5px;
  font-size: 13px;
  min-width: 80px;
}

.bold {
  font-weight: var(--bold) !important;
}

.efunder-btn:hover,
.efunder-btn:focus {
  background-color: var(--bg-theme);
  color: var(--bs-white);
}

.efunder-btn:hover {
  text-decoration: none;
}

.invoice-tab:before {
  position: absolute;
  top: 0;
  left: -30px;
  width: 150%;
  height: 1px;
  border-top: 1px solid var(--bg-grayd2);
  content: "";
  z-index: 1;
}

.invoice-tab .nav-tabs .active,
.tab-content .active {
  width: auto;
  height: auto;
  background: transparent;
  border-radius: 0;
  text-align: center;
  padding-top: 0;
  margin-left: 0;
}

.invoice-tab .nav-tabs {
  border-bottom: none;
}

.invoice-tab .nav {
  display: block;
  padding-bottom: 30px;
  text-align: center;
}

.invoice-tab .nav-tabs .nav-link.active,
.invoice-tab .nav-tabs .nav-item.show .nav-link {
  border: none;
  background-color: transparent;
  color: var(--bs-dark);
}

.invoice-tab .nav-tabs .nav-item {
  padding: 0;
}

.invoice-tab .nav-tabs .nav-link {
  display: inline-block;
  color: var(--bs-dark);
  margin: 0 28px 0 13px;
  border: none;
  font-size: 20px;
  position: relative;
}

.invoice-tab .nav-tabs .nav-link:after {
  position: absolute;
  bottom: 2px;
  left: 0;
  width: 100%;
  height: 2px;
  opacity: 0;
  background-color: var(--bs-dark);
  content: "";
  z-index: 1;
}

.invoice-tab .nav-tabs .nav-link.active:after,
.invoice-tab .nav-tabs .nav-item.show .nav-link:after {
  opacity: 1;
}

.apexcharts-active {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.bdrDsn {
  max-height: 420px !important;
}

.notification-wrap .card {
  margin-bottom: 25px;
  border: none;
  background-color: transparent;
}

.notification-wrap .card-header {
  padding: 0;
  cursor: pointer;
  border-bottom: none;
  background-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.imgButton,
.imgButtonActive {
  width: 80px;
  height: 80px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid var(--border-100);
}

.imgButton img,
.imgButtonActive img {
  width: 45px !important;
  height: 45px !important;
}

.imgButtonActive {
  border: 2px solid var(--bg-theme);
}

.notification-wrap .card-body {
  padding: 0;
}

.notification-wrap .card-body p {
  margin-bottom: 0;
  font-weight: 200;
  font-size: 12px;
  letter-spacing: 0;
}

.notification-item h5 {
  color: var(--bg-theme);
  font-size: 12px;
}

.notification-item p {
  font-size: 12px;
}

.arrow-down {
  display: inline-block;
  height: 10px;
  width: 10px;
  margin-left: 10px;
  position: relative;
  top: -3px;
  border-style: solid;
  border-color: var(--bg-theme);
  border-width: 0px 1px 1px 0px;
  transform: rotate(45deg);
  transition: border-width 150ms ease-in-out;
}

.notification-item h6 {
  color: var(--bs-dark);
}

.notification-info {
  display: none;
}

.notification-title {
  color: var(--bg-theme);
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .5);
  -webkit-background: rgba(0, 0, 0, .5);
}

.loader:after {
  content: '';
  width: 110px;
  height: 110px;
  background: var(--bs-white);
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: -1;
  border-radius: 100px;
}

.loader .spinner-border {
  width: 70px;
  height: 70px;
  border-right-color: var(--bg-theme);
}

.save_n_exit.efunder-btn {
  color: var(--bs-white);
}

button {
  border: none;
  outline: none !important;
  background: none;
}

.createReqBtn {
  height: 38px;
  line-height: 0;
  background: var(--bg-theme);
  border-radius: 8px;
  text-align: center;
  outline: 0px;
  border: 1px solid var(--bg-theme);
  padding: 0 15px;
  font-style: normal;
  font-size: 13px;
  align-items: center;
  text-align: center;
  color: var(--bg-grayf2);
  transition: all ease-in-out .2s;
  -webkit-transition: all ease-in-out .2s;
}

.selectBPContainer button,
.selectBPContainer button:hover {
  border: 2px solid var(--bs-gray-200);
}

.selectBPContainer .imgButtonActive,
.selectBPContainer .imgButtonActive:hover {
  border-color: var(--bg-theme);
}

.regular-checkbox-three {
  display: inline-block;
  vertical-align: middle;
  margin: 0 !important;
  -webkit-appearance: none;
  background-color: var(--bs-white);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  display: inline-block !important;
  position: relative !important;
  width: 20px !important;
  height: 20px;
  border: 1px solid var(--bs-gray-300);
  border-radius: 4px;
  padding: 0 !important;
  outline: none;
}

.regular-checkbox-three:checked {
  background-color: var(--bs-white);
  border: 1px solid var(--bg-theme-light);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  color: var(--bg-yellow);
  outline: none;
}

.regular-checkbox-three:active {
  border: 1px solid var(--bg-theme-light);
}

.form-check-input:checked {
  background-color: var(--bg-theme-light) !important;
  border-color: var(--bg-theme-light) !important;
  box-shadow: none !important;
  vertical-align: bottom;
}

.form-check-input:focus {
  box-shadow: none !important;
}

.regular-checkbox:checked {
  background-color: var(--bs-white);
  border: 1px solid var(--bg-theme-light);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  color: var(--bg-yellow);
  outline: none;
}

.regular-checkbox:checked:after {
  content: '';
  font-size: 16px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--bg-theme-light) url(assests/check_icn.svg) no-repeat center center / 14px 10px;
}

.form-check-label {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
}

.cursor {
  cursor: pointer !important;
}

.color-text {
  color: var(--bg-theme);
  font-weight: 500 !important;
}

.text-through {
  text-decoration: line-through;
}

tr:hover {
  background-color: var(--bg-theme-light-300) !important;
}

._logout {
  cursor: pointer;
  margin-left: 135px;
  margin-top: 18px;
  margin-right: 50px;
}

svg._logout {
  width: 20px !important;
  height: 25px;
  fill: var(--bg-theme-light-200);
  color: var(--bg-theme-light-200);
}

.loaderGreen {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 999;
  margin: 0 auto;
  width: 150px;
  height: 150px;
}

.BuyerInfo {
  text-align: center;
  padding-right: 20px;
  cursor: pointer;
}

.form-control {
  border-radius: 6px !important;
  font-size: 14px !important;
  height: 40px !important;
}

textarea.form-control {
  height: auto !important;
}

label {
  font-size: 12px;
  letter-spacing: 0;
  font-weight: 800;
}

button label {
  font-size: 0;
}

.form-control:focus {
  border-color: var(--bg-theme-light) !important;
  box-shadow: none !important;
  font-size: 14px !important;
}

.form-control::-webkit-input-placeholder,
.form-control::-moz-placeholder,
.form-control:-ms-input-placeholder,
.form-control:-moz-placeholder {
  color: var(--bg-grayf2) !important;
  font-size: 14px;
}


.form-control::-webkit-input-placeholder {
  font-size: 14px;
}

.form-control::-moz-placeholder {
  font-size: 14px;
}

.form-control:-ms-input-placeholder {
  font-size: 14px;
}

.form-control:-moz-placeholder {
  font-size: 14px;
}


.viewFile {
  margin-left: -80px !important;
  position: relative;
  z-index: 5;
}

.form-check-input {
  height: 20px !important;
}

.remittances {
  position: absolute;
  top: 15px;
  right: 30px;
  z-index: 1;
}

.remittances a {
  display: inline-block;
  color: var(--bg-theme) !important;
  text-decoration: none !important;
  font-size: 11px;
  letter-spacing: 0;
}

.remittances a:hover {
  color: var(--hover) !important;
}

img.align-baseline {
  width: 12px;
}

.subSecTitle {
  font-style: normal;
  font-size: 11px;
  line-height: 21px;
  color: var(--bg-gray6b);
  letter-spacing: 0;
}

.viewMore {
  color: var(--bg-theme) !important;
}

.markRead {
  font-size: 11px;
  color: var(--bg-theme);
  letter-spacing: 0;
  font-weight: 200;
}

.markRead:hover {
  color: var(--hover);
}

.markReadGray {
  font-size: 11px;
  color: var(--bg-gray6b);
  letter-spacing: 0;
  font-weight: 200;
}

.alignClose {
  position: relative;
  padding-top: 40px !important;
  align-items: baseline !important;
  -webkit-align-items: baseline !important;
}

.tableresponsive {
  position: relative;
  padding: 8px;
  margin-top: 45px;
}

.tableresponsive:before {
  content: "";
  border: solid 1px var(--border-100);
  border-radius: 8px;
  padding: 8px;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}

.tableHead {
  position: relative;
  z-index: 1;
  margin-top: -45px;
  margin-bottom: 0;
}

.tableHead th {
  font-size: 12px;
  color: #393939;
  background: transparent !important;
  padding-top: 0;
  padding-bottom: 25px;
  white-space: nowrap;
}

table thead tr:hover {
  background: transparent !important;
}

.tableHead thead tr,
.tableHead tbody tr:last-child,
.tableHead tbody tr:last-child td {
  border-bottom: none !important;
}

.tblRqst {
  font-size: 12px;
  padding-top: 20px;
  padding-bottom: 12px;
}

.tableHead td {
  font-size: 12px;
}

.tableHead td.coName {
  white-space: nowrap;
}

.grayColor {
  color: rgb(117, 115, 115) !important;
}

.CR_label {
  font-style: normal;
  font-size: 12px;
  padding-bottom: 5px;
  line-height: 21px;
  color: var(--bg-gray37);
}

.chart-wrap div,
.chart-wrap div svg {
  transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
}

.min-w {
  min-width: 100% !important;
}

.languageDD .PhoneInputInput,
.languageDD .PhoneInputCountry {
  padding: 0 20px;
  height: 45px;
  line-height: 5;
  background: var(--bs-white);
  border: 1px solid var(--bg-gray6b);
  border-radius: 8px;
  font-size: 12px;
}

.languageDD .PhoneInputInput:focus {
  outline: none;
  box-shadow: none;
}

.pn-ProductNav_Wrapper,
.horiz-scroll-wrapper {
  position: relative;
  padding: 0 11px;
  box-sizing: border-box;
}

.pn-ProductNav,
.horiz-scroll-outer {
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  position: relative;
  font-size: 0;
}

.js .pn-ProductNav {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.js .pn-ProductNav::-webkit-scrollbar {
  display: none;
}

.pn-ProductNav_Contents {
  float: left;
  transition: transform 0.2s ease-in-out;
  position: relative;
}

.pn-ProductNav_Contents-no-transition {
  transition: none;
}

.pn-ProductNav_Link {
  text-decoration: none;
  color: var(--bg-gray88);
  font-size: 1.2rem;
  font-family: var(--gotham), -apple-system, sans-serif;
  display: inline-flex;
  align-items: center;
  min-height: 44px;
  border: 1px solid transparent;
  padding: 0 11px;
}

.pn-ProductNav_Link+.pn-ProductNav_Link {
  border-left-color: var(--bg-grayee);
}

.pn-ProductNav_Link[aria-selected="true"] {
  color: var(--bg-gray11);
}

.pn-Advancer {
  appearance: none;
  background: transparent;
  padding: 0;
  border: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.3s;
}

.svg-inline--fa:focus {
  outline: none;
}

.pn-Advancer:focus {
  outline: 0;
}

.pn-Advancer:hover {
  cursor: pointer;
}

.pn-Advancer_Left {
  left: 0;
}

[data-overflowing="both"]~.pn-Advancer_Left,
[data-overflowing="left"]~.pn-Advancer_Left {
  opacity: 1;
}

.pn-Advancer_Right {
  right: 0;
}

[data-overflowing="both"]~.pn-Advancer_Right,
[data-overflowing="right"]~.pn-Advancer_Right {
  opacity: 1;
}

.pn-Advancer_Icon {
  width: 20px;
  height: 44px;
  fill: var(--bg-graybb);
}

.pn-ProductNav_Indicator {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  width: 100px;
  background-color: transparent;
  transform-origin: 0 0;
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.chOption button .sr-only {
  display: none !important;
}

/*modal popup*/
.text_teal {
  color: var(--bg-theme);
}

.Thescroll {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
}

.Thescroll h4 {
  font-size: 18px;
}

.Thescroll h3.pt-2 {
  font-size: 19px;
}

.CR_card_status {
  font-style: normal;
  font-size: 12px;
  line-height: 21px;
  text-align: left;
  color: var(--bg-red-light-100);
  margin-bottom: 15px;
  letter-spacing: 0;
}

.CR_card_status svg {
  font-size: 10px;
}

.details-item {
  padding-bottom: 20px;
}

.details-item p {
  font-size: 12px;
  margin-bottom: 8px;
  color: var(--bg-gray6b);
}

.details-item h3 {
  font-size: 12px;
  color: var(--bs-black);
}

.inner-table .table th,
.inner-table .table td {
  padding: 0;
}

.inner-table h3 {
  font-size: 12px;
  margin-bottom: 0;
}

.invoice-tab {
  padding-top: 15px;
  position: relative;
}

/*Modal Direction*/
.fade.modal-backdrop,
.mdlDir .modal-header,
.mdlDir .modal-body,
.mdlDir {
  background: transparent;
}

.mdlDir .modal-header {
  box-shadow: none;
  padding-top: 30px;
  font-size: 19px;
  line-height: 36px;
  font-weight: 500;
}

.pb-100.modal-body {
  padding-top: 0 !important;
  padding-bottom: 100px !important;
}

.alignClose {
  position: relative;
  padding-top: 40px !important;
  font-size: 28px;
  line-height: 36px;
  align-items: baseline !important;
  -webkit-align-items: baseline !important;
}

.alignClose button {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
  height: 12px;
  line-height: 0;
  padding: 0;
}

.ModalDemo {
  margin-top: 70px;
}

.modal.ModalDemo .modal-dialog {
  width: 430px;
  height: 100%;
  height: calc(100% - 70px);
  margin-top: 0;
  margin-right: 0;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.ModalDemo .modal-content {
  height: 100%;
  overflow-y: auto;
  background: var(--bg-theme-light-300);
  transition: all .5s ease-in-out;
  -webkit-transition: all .5s ease-in-out;
  box-shadow: -4px 0 6px rgb(0 0 0 / 6%);
  border-radius: 0 24px 0 0;
}

.modal.ModalDemo .modal-body {
  padding: 15px 15px 80px;
}

.modal.ModalDemo.fade .modal-dialog {
  right: -500px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.ModalDemo.fade.show .modal-dialog {
  right: 0;
}

.Tabs_content {
  padding-top: 0;
}

.invoice-tab nav.nav a,
.Tabs_content nav.nav a {
  background: var(--bs-white) !important;
  font-weight: 400 !important;
  padding: 5px 10px !important;
  margin: 0 5px !important;
  text-decoration: none;
  font-size: 14px !important;
  border: solid 1px var(--bg-graye6) !important;
}

.invoice-tab nav.nav a.active,
.Tabs_content nav.nav a.active {
  border-color: var(--bg-theme) !important;
  border-radius: .25rem !important;
  color: var(--bg-theme) !important;
}

.invoice-tab nav.nav a:after,
.Tabs_content nav.nav a:after {
  display: none !important;
}

.text-black {
  color: var(--bs-black);
}

.Tabs_content::before {
  display: none;
}

.footer button {
  font-size: 12px;
  color: var(--bg-theme);
  text-decoration: underline;
}

._formTC .CaPara {
  margin-bottom: 0;
}

._formTC .dwnTC {
  font-size: 14px;
  color: var(--bg-theme);
  text-decoration: underline;
}

._form .CaPara {
  font-size: 12px;
}

.clickevent {
  font-size: 14px;
  color: var(--bg-theme);
  text-decoration: underline;
}

.welcome_title {
  font-family: var(--gotham);
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  color: var(--bg-gray37);
}

.D_label {
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  color: var(--bg-gray37);
  letter-spacing: 0;
}

.addBuyerPlatforminDashboard_text {
  font-style: normal;
  font-size: 12px;
  line-height: 21px;
  text-align: center;
  color: var(--bg-theme-light);
  margin-top: 6px;
}


/*default tag*/

p {
  font-family: var(--gotham) !important;
}

.nameField p {
  position: static !important;
}

.text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--bg-gray6b);
  /* margin-left: 3px; */
}

.text.small16 {
  font-size: 12px;
  font-weight: 200;
}

.secRight .infoIcn {
  float: right;
}

.D_card {
  width: 250px;
  background-color: var(--bg-theme-light-100);
  border-radius: 24px;
  margin: 12px;
  padding: 15px;
  transition: 0.2s;
  float: left;
  margin-left: 0px;
}

.CR_card_text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: var(--bg-gray37);
}

#Dashboardwrapper {
  height: 150px;
  width: 1250px;
  overflow-x: auto;
  overflow-y: hidden;
  display: inline-flex;
}

#Dashboardwrapper>div {
  width: auto !important;
}

#Dashboardwrapper::-webkit-scrollbar {
  height: 10px;
  background-color: var(--bg-white-200);
}

#Dashboardwrapper::-webkit-scrollbar-thumb:horizontal {
  background-color: var(--bg-grayd2);
  border-radius: 10px;
}

.CR_buyerPlatform_Icon_container {
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  border-radius: 6px;
  margin: 5px auto 0;
  text-align: center;
}

.CR_buyerPlatform_Icon_container img {
  margin-top: -1px !important;
  width: 20px !important;
  height: 20px !important;
}

.CR_buyerPlatform_icon_title {
  font-size: 11px;
  font-weight: 400;
  line-height: 21px;
  margin-top: 6px;
  font-style: normal;
  text-align: center;
  letter-spacing: 0;
}

.addBuyerPlatforminDashboard {
  width: 30px;
  height: 30px;
  border: 1px solid var(--bg-theme-light);
  box-sizing: border-box;
  border-radius: 6px;
  text-align: center;
  margin-left: 12px;
}

.addSign {
  font-weight: normal;
  font-size: 26px;
  line-height: 29px;
  color: var(--bg-theme-light);
  margin: 0 auto !important;
}

.donetGraphSection {
  background-color: var(--bg-theme-light-100);
  border-radius: 24px;
}

.subSection {
  height: 92px;
  background: var(--bg-theme-light-300);
  margin-bottom: 10px;
  border: solid 1px var(--bs-white);
  border-radius: 24px;
  transition: all ease-in-out .2s;
  -webkit-transition: all ease-in-out .2s;
  position: relative;
}

.subSection-two {
  cursor: pointer;
}

.totalAmount {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  color: var(--bg-theme-light);
  margin-left: 10px;
}

.totalAmount span {
  display: inline-block;
  vertical-align: middle;
}

.totalAmount p {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 10px;
  font-size: 12px;
}

.col-sm-12.subSecTitle {
  padding: 15px 10px 10px 10px !important;
}

.col-sm-12.subSecTitle svg {
  font-size: 10px;
  margin-top: 2px;
}

.P_card {
  height: 110px;
  background-color: var(--bg-theme-light-300);
  border-radius: 8px;
  margin-bottom: 35px;
}

.P_card_title {
  font-style: normal;
  font-size: 12px !important;
  line-height: 21px;
  color: var(--bg-gray37);
}

.P_card_title span {
  font-weight: bold;
}

.table.text-start {
  font-size: 12px;
}

.listVal {
  margin-bottom: 35px;
}

.listVal h6 {
  font-size: 14px;
  color: var(--bg-gray6b);
}

.listVal ul {
  list-style: none;
  margin: 0;
  padding: 0 0 0 5%;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.listVal ul li p {
  font-size: 12px;
  font-weight: 500;
  color: var(--bg-gray6b);
  margin-top: 10px;
  margin-bottom: 5px;
  letter-spacing: 0;
}

.otpText {
  margin-left: auto;
  color: var(--bg-theme-light) !important;
  text-decoration: none;
  font-weight: 500;
  letter-spacing: 0
}

.otp_form._form .welcome {
  line-height: normal;
  margin: 0;
  padding: 0 0 5px 0;
}

.otp_form._form .sub_wel {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0
}

.body-title {
  margin-top: 25px;
  margin-left: 42px;
  padding: 1px;
  width: 680px;
  background: var(--bg-theme);
  border-radius: 24px;
}

.body-title-text {
  margin-left: 150px;
  margin-top: 31px;
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 21px;
  color: var(--bs-white);
}

.title-text {
  height: 36px;
  margin-left: 151px;
  margin-right: 30px;
  margin-top: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: var(--bg-grayf2);
}

.statusHighlight {
  background: var(--bg-theme-light-100);
}

a.toggleBtn {
  display: none;
}

.notify_counter {
  position: relative;
}

.notify_counter .notify_icn {
  left: 6px;
  top: -15px !important;
  height: 18px !important;
  width: 18px !important;
}

.notify_counter .notify_icn text {
  font-size: 14px !important;
}

.infinite-scroll-component {
  overflow: hidden !important;
}

.createReqBtn.text-decoration-none {
  padding-top: 19px !important;
  padding-bottom: 19px !important;
  margin-top: 5px !important;
  font-size: 18px !important;
}

/*date picker*/
.react-google-flight-datepicker .day .background-day.first-day,
.react-google-flight-datepicker .day:hover::after,
.react-google-flight-datepicker .date:focus::after,
.react-google-flight-datepicker .date.is-focus::after {
  border-color: var(--bg-theme) !important;
  margin: 0 !important;
}

.react-google-flight-datepicker svg {
  fill: var(--bg-theme) !important;
}

.react-google-flight-datepicker .day.selected::after,
.react-google-flight-datepicker .dialog-footer .submit-button,
.react-google-flight-datepicker .day.selected.hovered::after {
  background-color: var(--bg-theme) !important;
}

.react-google-flight-datepicker .day.hovered {
  background: #d9fdfb !important;
}

.react-google-flight-datepicker .date:focus,
.react-google-flight-datepicker .date.is-focus {
  background: var(--bs-white) !important;
}

.react-google-flight-datepicker #end-date-input-button {
  border: 1px solid #dadce0;
  margin-left: 5%;
  width: 45%;
  height: 33px;
}

.react-google-flight-datepicker .date {
  height: 33px !important;
}

.spinLoader,
.spinLoader img {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  z-index: 9;
}

.spinLoader svg path,
.spinLoader svg g {
  fill: var(--bg-theme);
  stroke: var(--bg-theme);
}

.spinLoader:before {
  content: "";
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: var(--bs-black);
  opacity: .6;
  z-index: 0;
}

.chartOption {
  font-size: 14px;
  display: block;
}

.chartOption .colorone {
  margin-bottom: 10px;
}

.chartOption .dataDirectionLeft {
  position: relative;
  top: -1px;
  margin-right: 5px;
}

.chartOption .dataDirectionMid {
  color: var(--bs-gray-600);
  margin-right: 10px;
  display: inline-block;
  width: 60%;
}

.chartOption .dataDirectionRight {
  color: var(--color-696);
  margin-left: auto;
}

.chartOption .dataDirectionRight02 {
  color: var(--colordc4);
  margin-left: auto;
}

.chartOption .dataDirectionRight03 {
  color: var(--bs-gray-400);
  margin-left: auto;
}

.chartOption .dataDirectionRight04 {
  color: var(--color-666);
  margin-left: auto;
}

.horozontalLine g line {
  display: none;
}

.horozontalLine g line:first-child {
  display: block;
}

.verticalLine g g rect[stroke="none"] {
  fill: #fff;
}

.verticalLine g g rect[stroke="none"]:first-child {
  fill: #000;
}

.react-google-flight-datepicker .DatePickerCal .date-picker-date-group,
.react-google-flight-datepicker .DatePickerCal .date {
  height: 28px;
}

.react-google-flight-datepicker .date:focus::after,
.react-google-flight-datepicker .date.is-focus::after {
  border-width: 1px;
}

.DatePickerCal .dialog-date-picker {
  left: auto !important;
  right: 0;
}

.react-google-flight-datepicker .DatePickerCal .date-picker-input {
  height: 30px;
  border: none !important;
}

.react-google-flight-datepicker .change-date-group .change-date-button {
  padding-top: 4px !important;
}

.react-google-flight-datepicker .dialog-header .date-picker-input {
  width: 55%;
}

.react-google-flight-datepicker .change-date-group {
  width: 35px !important;
}

.react-google-flight-datepicker .date-picker-input svg {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.react-google-flight-datepicker .date {
  padding-left: 5px !important;
  padding-right: 5px !important;
  z-index: 0;
}

.d-inline-block.px-4.py-4.mt-2 {
  padding: 15px 14px 16px 14px !important;
  border-radius: 7px;
  font-size: 14px !important;
  margin-top: 7px !important;
}

.positiontop {
  /* top: -40px; */
  top: -29px;
}

.border-none .accordionTR td {
  border: none !important;
}

.border-none .accordionTR .show {
  border-bottom: 1px solid var(--border-100);
  padding-bottom: 10px;
}

.accordionTR p {
  font-family: var(--gotham) !important;
  margin-bottom: 8px;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: var(--bg-gray6b) !important;
}

.overflowhidden .modal-content {
  overflow: hidden;
}

.tblPlan th,
.tblPlan td {
  font-size: 14px;
  color: var(--bg-gray37);
}

.graph01 svg g>g>g>rect[fill="#ebebeb"],
.graph01 svg g>g>g>rect[fill="#cccccc"] {
  display: none !important;
}

.graph01 svg g>g>g>rect:first-child[fill="#cccccc"] {
  display: block !important;
}

/* .ModalDemo .table th {font-size: 14px; padding: 0.6rem 0.5rem 0.5rem 0.5rem;}
.ModalDemo .table td {font-size: 12px; padding: 0.6rem 0.5rem 0.5rem 0.5rem;} */
.ModalDemo .table th {
  font-size: 14px;
  padding: 0.6rem 0 0.5rem 0;
}

.ModalDemo .table td {
  font-size: 12px;
  padding: 0.6rem 0 0.5rem 0;
}

.graph01 svg text {
  font-style: normal !important;
  font-size: 12px;
}

table.sttlMent td h3 {
  margin: 0 !important;
}

.tableLayout-fix {
  table-layout: fixed !important;
}

.detailModelBuyerType div {
  margin: 10px;
}

.detailModelBuyerType div h4 {
  margin: 0;
}

.detailModelBuyerType div p {
  font-size: 12px;
  margin: 0;
  height: 20px;
}

.createRequest_container_new table.tableBuyerPlat th:first-child {
  width: 150px;
}

.accordion-title {
  padding-right: 20px;
  position: relative;
}

.accordion-title svg {
  float: right;
}

.invalid-feedback-cus {
  width: 100%;
  font-size: 13px;
  color: #dc3545;
}

#card {
  width: 250px;
  background-color: var(--bg-theme-light-100);
  border-radius: 24px;
  margin: 0 0 12px 0;
  padding: 15px;
  transition: 0.2s;
  float: left;
  margin-left: 0px;
}

.Card_label {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 21px;
  margin: 0 0 10px 0;
  color: var(--bg-gray37);
}

.card_Text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: var(--bg-gray37);
  padding: 0;
}

.fillNow {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 21px;
  margin-bottom: 0;
  padding: 0;
  color: var(--bg-theme);
}

.statewrapnew .accordion-item {
  border-radius: 10px !important;
  margin-top: 10px;
  border-top: 1px solid rgba(0,0,0,.125) !important;
}

.statewrapnew .accordion-item button {
  background: transparent !important;
  font-size: 14px;
}


@media (min-width: 1024px) and (max-width: 1559px) {
  #container {
    padding-right: 20px;
    padding-left: 20px;
  }

  #Dashboardwrapper>div {
    width: auto !important;
  }

  .icon {
    margin-left: 20px !important;
  }

  .footer {
    width: 160px;
    right: 0;
    left: 0;
    margin: 0 auto;
  }

  .D_card {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  div.subSection {
    margin-bottom: 5px;
    height: 100px;
  }

  .row.dashboard-right .col-sm-3 {
    padding-left: 0px !important;
    padding-right: 5px !important;
  }

  .secRight .smBlock {
    width: 85%;
    line-height: 1.2;
    display: inline-block;
    vertical-align: top;
  }
}

@media screen and (max-width:991px) {
  .body-title {
    width: 100%;
  }

  .viewMerchant_container_new .text {
    word-break: break-all;
  }

  .viewMerchant_container_new .account_data {
    margin-top: 0;
  }

  .row.cmpDTL .col-md-12.col-lg-8 {
    padding: 0 !important;
  }

  .row.cmpDTL .col-lg-4.text,
  .row.cmpDTL .col-lg-4.label_text {
    margin: 0 !important;
  }

  .row.cmpDTL .col-lg-8 .row:nth-child(even) {
    margin-bottom: 15px !important;
  }

  .row.docBox .doc_text {
    margin: 0 !important;
  }

  .viewMerchant_container_new hr {
    margin: 25px 0 0 0 !important;
  }

  button.halftab {
    font-size: 14px;
    padding-right: .7rem !important;
    padding-left: .7rem !important;
  }
}

@media screen and (max-width:767px) {

  a.toggleBtn {
    position: fixed;
    left: 11px;
    top: 13px;
    z-index: 999;
    font-size: 0;
    width: 35px;
    height: 35px;
    opacity: 0;
    display: inline-block;
  }

  /*sidebar*/
  div.sidebar {
    transition: all ease-in-out .4s;
    -webkit-transition: all ease-in-out .4s;
    left: -100%;
    z-index: 5;
  }

  .slideBar::before {
    content: "";
    position: fixed;
    left: -100%;
    top: 0;
    background: #000;
    width: 100%;
    height: 100%;
    opacity: .4;
    z-index: 4;
    transition: all ease-in-out .3s;
    -webkit-transition: all ease-in-out .3s;
  }

  .slideBar.active_sidebar::before {
    left: 0;
  }

  .active_sidebar div.sidebar {
    left: 0;
  }

  .login-inner {
    padding: 10px 15px 0 !important;
  }

  div.login-wrap {
    background: transparent !important;
  }

  div.login-wrap .row.justify-content-between {
    height: auto;
  }

  div.login-wrap .leftPos {
    background: var(--bg-theme);
    border-radius: 0 0 50px 50px;
    padding-bottom: 20px;
  }

  #main_Header .right-content {
    width: 40%;
  }

  #main_Header .right-content ._name {
    margin-left: 15px;
  }

  #main_Header .right-content svg._logout {
    margin-left: 85px;
  }

  .top_left_title {
    padding-left: 10px;
  }

  .top_left_title #toggle {
    display: inline-block;
  }

  .leftPos .logog {
    padding: 30px 0 !important;
    text-align: center;
  }

  .leftPos .logog img {
    width: 55% !important;
  }

  .leftPos .feature1 {
    width: 25%;
    margin: 0 0 15px 0;
    float: left;
    text-align: center;
  }

  .leftPos .square {
    float: none !important;
    display: inline-block;
  }

  .leftPos .inColumn {
    float: none !important;
    display: block;
    padding: 0;
  }

  .leftPos .feature1 .smallLine {
    display: none;
  }

  .leftPos .bigLine {
    line-height: 1.4;
    font-size: 14px;
    padding-top: 12px;
    font-size: 12px;
  }

  .rightPos ._form .CaPara {
    text-align: center;
    margin-top: -20px;
  }

  .rightPos.pt-80 {
    padding-top: 0 !important;
  }

  .rightPos .form {
    padding-top: 15px !important;
    margin-top: 0 !important;
  }

  .rightPos ._form .welcome {
    display: block;
    text-align: center;
    font-weight: 500;
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .rightPos ._form .inputLabel {
    font-size: 12px;
  }

  .rightPos ._form .inputLabel.mt-4 {
    margin-top: 10px !important;
  }

  .rightPos form.acc_form p {
    display: block !important;
    margin: 10px 0 0 0 !important;
  }

  .rightPos form.acc_form label.MuiFormLabel-root {
    font-size: 12px !important;
    line-height: 17px !important;
    margin-top: 0 !important;
  }

  .already-label {
    padding-top: 15px !important;
  }

  label.fp {
    padding-top: 0;
    padding-bottom: 5px;
  }

  button.CreateAccformLogin {
    font-size: 14px;
  }

  #container {
    margin-left: 0;
    padding: 25px 15px 30px 15px;
  }

  .welcome_title {
    font-size: 26px !important;
    margin-bottom: 10px;
    letter-spacing: -1px;
    margin-top: 10px;
  }

  button.createReqBtn {
    width: 100% !important;
    border-radius: 4px;
    font-size: 20px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    text-align: left;
    line-height: 15px;
  }

  button.createReqBtn svg {
    float: right;
  }

  #Dashboardwrapper {
    width: 92% !important;
    padding-left: 0;
    margin-left: 15px;
    margin-right: 15px;
  }

  .D_card {
    width: 200px;
  }

  .D_label {
    margin-bottom: 5px;
  }

  div.row.rowNew,
  div.scrolbarRow {
    overflow: auto;
    white-space: nowrap;
    flex-wrap: nowrap !important;
    -webkit-flex-wrap: nowrap !important;
    margin: 0 !important;
    position: relative;
  }

  div.row.rowNew .col-sm-auto,
  .scrolbarRow .col-sm-auto {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .scrolbarRow .cursor.colLast {
    position: sticky;
    right: 0;
    background: var(--bs-white);
  }

  .donetGraphSection .donutChart {
    flex: 0 0 auto;
    width: 36.66666667%;
  }

  .donetGraphSection .fndBal {
    flex: 0 0 auto;
    width: 63.33333337%;
    padding-right: 20px;
  }

  .donetGraphSection .lblSize,
  .donetGraphSection .subSecTitle {
    padding-left: 0 !important;
    line-height: 2;
    margin-left: -5px !important;
  }

  .donutChart .chart-wrap {
    margin-left: -20px;
  }

  .subSecTitle,
  #BPwrapper>div {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .remittances {
    top: 5px;
    right: 15px;
  }

  #BuyerPlatformGraphDatainDashboard .secRight {
    margin-top: 5px !important;
  }

  #BuyerPlatformGraphDatainDashboard .secRight .dashboard-right {
    padding-left: 10px;
    padding-right: 10px;
  }

  #BuyerPlatformGraphDatainDashboard .secRight .col-sm-3 {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 2px;
  }

  #BuyerPlatformGraphDatainDashboard .subSection {
    margin-bottom: 4px;
  }

  /*all popup*/
  .modal.ModalDemo .modal-dialog {
    width: 350px;
    float: right;
  }

  /*table*/
  .tableresponsive table {
    overflow: auto;
    display: block;
  }

  /*tooltop*/
  .__react_component_tooltip {
    width: 70%;
  }

  .smMobile {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .mtMobile.mt-5 {
    margin-top: 0 !important;
  }

  .rowHead {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .text {
    margin-left: 0;
  }

  .CR_card {
    padding: 15px !important;
    min-height: inherit !important;
  }

  .blueSecMob {
    margin-top: 15px !important;
  }

  .boxesPart.row {
    margin: 0 !important;
  }

  .boxesPart.row .CR_card.sizewidth {
    width: 100% !important;
  }

  .boxesPart.row .col-auto {
    width: 100% !important;
    padding: 0 !important;
  }

  .boxesPart.row .col-sm-6 {
    padding: 0 !important;
  }

  .boxesPart.row .col-sm-6 .CR_card {
    width: 100% !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .boxesPart.row .col-auto .row,
  .boxesPart.row .col-sm-6 .CR_card .row {
    margin: 0 !important;
  }

  .rowH .row.m-0 {
    justify-content: space-between;
    -webkit-justify-content: space-between;
  }

  .rowH .row.m-0 .CR_selectInvoice {
    width: 21%;
    padding-left: 8px;
  }

  .rowH .row.m-0 .CR_amount {
    width: 32%;
  }

  .rowH .row.m-0 a.efunder-btn {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 12px;
  }

  .rowH .row.m-0 span.w-100 {
    font-size: 12px;
    font-weight: 100;
    letter-spacing: normal;
    padding-left: 0;
    padding-right: 0;
  }

  .row_new .tab {
    width: auto;
  }

  .row_new .halftab {
    width: 50%;
    line-height: 1;
    font-size: 14px;
  }

  .rowReq {
    margin: 0 !important;
  }

  .rowReq .col-sm-4,
  .rowReq .col-sm-8 {
    padding: 0 !important;
  }

  .selectBPContainer,
  .selectBPContainer_old {
    justify-content: space-around;
    -webkit-justify-content: space-around;
  }

  .imgButton,
  .imgButtonActive {
    width: 60px;
    height: 60px;
    margin: 0 auto;
  }

  .nowrapMobile {
    word-wrap: normal !important;
    word-break: normal !important;
  }

  .checkMobile input {
    vertical-align: top !important;
  }

  .checkMobile label {
    width: 90%;
  }

  #BPwrapper {
    height: auto !important;
  }

  .setByform.formMobile .row .col-10.col-sm-10 {
    padding-top: 0;
    margin-top: 0;
  }

  .setByform.formMobile .row .col-10.col-sm-10 p.mt-1 {
    margin-top: 7px !important;
    margin-bottom: 7px !important;
  }

  .setByform.formMobile .uploadBTN .view_dlt {
    margin-top: 6px;
  }

  .row.setByform,
  #BPwrapper .row {
    margin: 0;
  }

  .formMobile .col-sm-7,
  .formMobile .col-md-2.mt-3,
  .formMobile .col-md-2.mt-2,
  .row.setByform .col-md-12 {
    padding: 0;
    margin-bottom: 7px;
  }

  .formMobile .col-sm-7 .form_text,
  .formMobile .col-md-2.mt-3 .form_text,
  .formMobile .col-md-2.mt-2 .form_text,
  .row.setByform .col-md-12 .form_text {
    margin-top: 0 !important;
  }

  .formMobile .col-md-2.mt-2 {
    margin-bottom: 5px;
  }

  .formMobile .col-10 p.mt-1 {
    margin: 12px 0 !important;
  }

  .formMobile .col-2 .view_dlt {
    margin-top: 10px;
  }

  nav.user-libeary-tabs {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .user-libeary-tabs .nav-item.nav-link {
    min-width: auto !important;
    letter-spacing: 0 !important;
  }

  .tpMobile {
    margin-top: 10px !important;
    margin: 0 !important;
  }

  .viewMerchant_container_new .row {
    margin-left: 0;
    margin-right: 0;
  }

  .viewMerchant_container_new .row.account_data {
    margin-top: 10px;
  }

  .viewMerchant_container_new .row.account_data .col-sm-10 {
    padding-left: 0;
    padding-right: 0;
  }

  .viewMerchant_container_new .row.account_data .col-sm-10 .col-sm-5,
  .viewMerchant_container_new .row.account_data .col-sm-10 .col-sm-4,
  .viewMerchant_container_new .row.account_data .col-sm-10 .col-sm-auto,
  .viewMerchant_container_new .col-sm-8.ms-3 .col-sm-4.label_text {
    width: 100% !important;
    display: flex;
    justify-content: space-between;
  }

  .viewMerchant_container_new .col-sm-8.ms-3 .col-sm-4.label_text p {
    margin: 0 !important;
  }

  .viewMerchant_container_new .sub_title {
    margin-top: 10px;
  }

  .viewMerchant_container_new .col-sm-4.text,
  .viewMerchant_container_new .col-sm-4.label_text {
    margin: 0 !important;
    padding: 8px 0 !important;
  }

  .col-sm-8.ms-3 {
    margin-left: 0 !important;
  }

  .row.dcFile .input-group {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .row.dcFile .input-group .doc_text {
    margin: 5px 0 !important;
  }

  span.w-100.mt-1 {
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 0;
  }

  .modal.ModalDemo .modal-body {
    padding-bottom: 0;
  }

  h2.sub_title {
    font-size: 19px;
  }

  .hrmobile {
    margin: 10px 0 15px 0 !important;
  }

  .id_date_box {
    margin-bottom: 20px;
    padding-left: 0 !important;
    padding-top: 10px !important;
  }

  .rowThird .col-sm-4,
  .fn_option .col-sm-4,
  .rowSecond .col-sm-4 {
    width: 50%;
    padding-left: 0;
  }

  .fn_option .col-sm-4 p {
    line-height: normal;
  }

  .rowThird .col-sm-4 .input-group,
  .rowSecond .col-sm-4 .input-group {
    padding-left: 0;
  }

  .rowThird .col-sm-4 .col-sm-4 {
    width: 100%;
  }

  .rowThird .small16.ms-3 {
    word-break: break-all;
  }

  .notification-wrap .card {
    margin-bottom: 10px;
  }

  input.form-control {
    font-size: 12px !important;
  }

  #main_Header .right-content svg {
    font-size: 25px !important;
    margin-right: 15px;
  }

  .notify_icn {
    right: 120px !important;
  }

  .notify_icn text {
    font-size: 12px !important;
  }
}

@media screen and (max-width:1199px) {
  #main {
    padding: 0 !important;
  }
}

@media screen and (max-width:575px) {
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto !important;
    max-width: 100%;
  }

  #main_Header .right-content svg._logout {
    margin-left: 75px !important;
  }

  #main_Header .right-content ._name {
    margin-left: 5px;
  }
}